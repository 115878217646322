import React, { Component } from "react";
import { connect } from "react-redux";
import dynamic from "next/dynamic";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import { actions as mainActions } from "@/bit/reducers/reducers.main-reducer";
import Carousel from "@/bit/components/components.carousel";
import { whichAnimationEvent } from "./utils";
import Card from "./card";
import TextInfo from "@/bit/components/components.text";

class ServiceCard extends Component {
  constructor(props) {
    super(props);
  }

  openModal = () => {
    const { addModal } = this.props;
    const BuyBoxModal = dynamic(() => import("./modal"));
    const device = getDeviceKindDependsOnViewPort();
    addModal({
      id: "cnmc",
      Element2Show: BuyBoxModal,
      modalProps: {
        contentPosition:
          device !== "MOBILE" ? "center--center" : "center--bottom",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeIn",
        contentInsideContainer: false,
        outSceneEffect: "fadeOut",
        closeByClickingOutside: true,
        scrollAllowed: false,
      },
      elementProps: {},
    });
  };

  handleLegalModal = (product) => {
    const { addModal } = this.props;
    const LegalModal = dynamic(() =>
      import("@/bit/components/components.legal-modal")
    );
    addModal({
      id: "legal-modal",
      Element2Show: LegalModal,
      modalProps: {
        contentPosition: "right--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeIn",
        contentInsideContainer: false,
        outSceneEffect: "fadeOut",
        closeByClickingOutside: true,
        scrollAllowed: false,
        createAnchor: false,
      },
      elementProps: {
        title: product["Legal-1"]?.["Text-1"] || "",
        description: product["Legal-1"]?.["Text-2"] || "",
        taggingInfo: taggingInfoLegalModal,
      },
    });
  };

  componentDidMount() {
    this.calculateProducts();
  }

  comparePrice = () => {
    const { orionInfo } = this.props;
    let differentPrices = false;
    if (orionInfo && orionInfo?.["Product-ZL"] && orionInfo?.["Product-ZR"]) {
      for (var [key, value] of Object.entries(orionInfo?.["Product-ZL"])) {
        const keySameProduct = Object.keys(orionInfo?.["Product-ZR"]).find(
          (element) => {
            return orionInfo?.["Product-ZR"]?.[element]?.alias === value?.alias;
          }
        );
        if (keySameProduct) {
          if (
            value?.["Price-2"]?.price !==
            orionInfo?.["Product-ZR"]?.[keySameProduct]?.["Price-2"]?.price
          ) {
            differentPrices = true;
          }
          if (
            orionInfo?.["Product-ZR"]?.[keySameProduct]?.["Label-1"] &&
            value?.["Label-1"]
          ) {
            if (
              value?.["Price-2"]?.price !==
              orionInfo?.["Product-ZR"]?.[keySameProduct]?.["Price-2"]?.price
            ) {
              differentPrices = true;
            }
          } else {
            if (
              orionInfo?.["Product-ZR"]?.[keySameProduct]?.["Label-1"] &&
              !value?.["Label-1"]
            )
              differentPrices = true;
            if (
              !orionInfo?.["Product-ZR"]?.[keySameProduct]?.["Label-1"] &&
              value?.["Label-1"]
            )
              differentPrices = true;
          }
        }
      }
    }
    if (
      orionInfo &&
      orionInfo?.["mimovistar-ZL"] &&
      orionInfo?.["mimovistar-ZR"]
    ) {
      for (var [key, value] of Object.entries(orionInfo?.["mimovistar-ZL"])) {
        const keySameProductMiMovistar = Object.keys(
          orionInfo?.["mimovistar-ZR"]
        ).find((element) => {
          return (
            orionInfo?.["mimovistar-ZR"]?.[element]?.alias === value?.alias
          );
        });
        if (keySameProductMiMovistar) {
          if (
            value?.["Price-2"]?.price !==
            orionInfo?.["mimovistar-ZR"]?.[keySameProductMiMovistar]?.[
              "Price-2"
            ]?.price
          ) {
            differentPrices = true;
          }
          if (
            orionInfo?.["mimovistar-ZR"]?.[keySameProductMiMovistar]?.[
              "Label-1"
            ] &&
            value?.["Label-1"]
          ) {
            if (
              value?.["Price-2"]?.price !==
              orionInfo?.["mimovistar-ZR"]?.[keySameProductMiMovistar]?.[
                "Price-2"
              ]?.price
            ) {
              differentPrices = true;
            }
          } else {
            if (
              orionInfo?.["mimovistar-ZR"]?.[keySameProductMiMovistar]?.[
                "Label-1"
              ] &&
              !value?.["Label-1"]
            )
              differentPrices = true;
            if (
              !orionInfo?.["mimovistar-ZR"]?.[keySameProductMiMovistar]?.[
                "Label-1"
              ] &&
              value?.["Label-1"]
            )
              differentPrices = true;
          }
        }
      }
    }
    return differentPrices;
  };

  componentDidUpdate() {
    const { refreshZone, setZoneRefreshFlag, cpModalClosed, orionInfo } =
      this.props;
    if (this.calculateProducts() && refreshZone && cpModalClosed) {
      setZoneRefreshFlag(false);
      const transitionEvent = whichAnimationEvent();
      const element = document.getElementById("animationReference");
      const validatePrice = this.comparePrice();
      if (transitionEvent && validatePrice) {
        element.addEventListener(transitionEvent, () => {
          setTimeout(() => {
            this.openModal();
          }, 100);
        });
      }
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { refreshZone } = this.props;
    // si ha cambiado
    const priceModalNeeded = nextProps.refreshZone && nextProps.cpModalClosed;
    const editandoCp = nextProps.refreshZone && !nextProps.cpModalClosed;
    if (editandoCp) {
      return false;
    }

    return !refreshZone || priceModalNeeded;
  };

  calculateProducts = () => {
    const products = [];
    const { orionInfo } = this.props;
    if (orionInfo && orionInfo?.["Product-ZL"]) {
      products.push(orionInfo["Product-ZL"]);
      return products.length;
    }
    if (orionInfo && orionInfo?.["Product-ZR"]) {
      products.push(orionInfo["Product-ZR"]);
      return products.length;
    }
  };

  render() {
    const { zone, cp, orionInfo, alias, x, logged } = this.props;

    let products = {};
    let productMimo = {};
    let numProduct = this.calculateProducts();
    const isQA = `${process.env.NEXT_PUBLIC_ENV_NAME}` === "qa";
    if ((orionInfo && orionInfo?.["Product-ZL"]) || orionInfo?.["Product-ZR"]) {
      switch (numProduct) {
        case 2:
          products = orionInfo?.["Product-ZL"];
          if (orionInfo?.["mimovistar-ZL"]) {
            productMimo = orionInfo?.["mimovistar-ZL"];
          }
          break;
        default:
          switch (zone) {
            case "ZR":
              products = orionInfo?.["Product-ZR"];
              if (orionInfo?.["mimovistar-ZR"]) {
                productMimo = orionInfo?.["mimovistar-ZR"];
              }
              break;
            case "ZL":
              products = orionInfo?.["Product-ZL"];
              if (orionInfo?.["mimovistar-ZL"]) {
                productMimo = orionInfo?.["mimovistar-ZL"];
              }
              break;
            default:
              products = orionInfo?.["Product-ZL"];
              if (orionInfo?.["mimovistar-ZL"]) {
                productMimo = orionInfo?.["mimovistar-ZL"];
              }
              break;
          }
          break;
      }
    }
    if (orionInfo && orionInfo?.["List-0"]) {
      products = orionInfo?.["List-0"];
    }
    const cardKeys = Object.keys(products).filter((key) =>
      /^Product-\d+$/.test(key)
    );
    const cardKeysMimo = Object.keys(productMimo).filter((key) =>
      /^Product-\d+$/.test(key)
    );
    const productValues = cardKeys.map((key) => products[key]);
    cardKeysMimo.map((key) => {
      const product = productMimo[key];
      const isPrivateCard = product.privateCard === "true";
      const isHideCard = product.privateCard === "hide";
      // if (isQA) {
      // productValues.push(product);
      // } else {
      if (`${x}` === "2" && !logged) {
        if (isPrivateCard) {
          productValues.unshift(product);
          if (`${process.env.NEXT_PUBLIC_ENV_NAME}` === "prod") {
            gtag("event", "cms_component", {
              component_name: "private-promotion",
              component_perso: "none",
              component_product: "movistar-base",
              component_variation: "distri-internet",
              event_type: "print-success",
            });
          }
        } else if (isHideCard) {
          return;
        }
      } else if (!isPrivateCard) productValues.push(product);
      // }
    });

    return (
      <div className="card-container container">
        {products["Text-1"] && products["Text-1"].text && (
          <div className="card-container--title">
            <TextInfo {...products["Text-1"]} cyData="RoamingCard Title" />
          </div>
        )}
        {productValues && productValues.length >= 6 ? (
          <div className="card-container-grid">
            {productValues &&
              Array.isArray(productValues) &&
              productValues.length > 0 &&
              productValues.map((card, index) => (
                <Card
                  {...card}
                  alias={alias}
                  key={`service-card-${index}`}
                  index={index}
                />
              ))}
          </div>
        ) : (
          <div className="card-container-carousel">
            <Carousel
              className="service-card-content"
              maxPointNum={0}
              breakpoint={768}
            >
              {productValues &&
                Array.isArray(productValues) &&
                productValues.length > 0 &&
                productValues.map((card, index) => (
                  <Card
                    {...card}
                    alias={alias}
                    key={`service-card-${index}`}
                    index={index}
                  />
                ))}
            </Carousel>
          </div>
        )}
        <style jsx>
          {`
            .card-container {
              margin-top: 1.5rem;
              @media screen and (max-width: 768px) {
                margin-top: 0.75rem;
              }
              &--title {
                margin-bottom: 1rem;
              }

              .card-container-carousel {
                :global(.carousel) {
                  :global(.carousel-window) {
                    gap: 24px;
                    @media screen and (max-width: 768px) {
                      gap: 12px;
                    }
                    :global(.carousel-item-wrapper) {
                      width: min-content;
                    }
                  }
                }
                .service-card-content {
                  display: inline-flex;
                  flex-direction: row;
                  gap: 24px;
                  width: 100%;

                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
              }

              .card-container-grid {
                display: grid;
                gap: 24px;
                grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

                @media screen and (max-width: 768px) {
                  gap: 12px;
                  grid-template-columns: repeat(12, 0fr);
                  overflow-x: auto;
                  overflow-y: hidden;
                }

                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  zone: state.main.zone,
  cpModalClosed: state.main.cpModalClosed,
  refreshZone: state.main.refreshZone,
  x: state.main.x,
  logged: state.main.logged,
});
const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
  setZoneRefreshFlag: (data) => dispatch(mainActions.setZoneRefreshFlag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCard);
