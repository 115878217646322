import React from 'react';
import TextInfo from '@/bit/components/components.text';
import Carousel from '@/bit/components/components.carousel';
import Card from './card';

const taggingInfo = {
  componentVariation: '',
  componentContent: '',
  eventType: 'click',
};

const SafeBoxCard = (props) => {
  const characKeys = Object.keys(props).filter((key) =>
    /^Charac-\d+$/.test(key)
  );

  const characValues = characKeys.map((key) => props[key]);

  return (
    <div className="safebox-container container" data-cy="fibre-pros-section">
      {props['Text-1'] && props['Text-1'].text && (
        <div className="safebox--title">
          <TextInfo {...props['Text-1']} cyData="SafeBoxCard Title" />
        </div>
      )}
      <div className="safebox-card-content">
        {characValues && characValues.length >= 6 ? (
          <div className="card-container-grid">
            {characValues &&
              Array.isArray(characValues) &&
              characValues.length &&
              characValues.map((card, index) => (
                <Card
                  {...card}
                  key={`safebox-card-${index}`}
                  index={index}
                  taggingInfo={taggingInfo}
                />
              ))}
          </div>
        ) : (
          <div className="card-container-carousel">
            <Carousel
              className="safebox-card-content"
              maxPointNum={0}
              breakpoint={768}
            >
              {characValues &&
                Array.isArray(characValues) &&
                characValues.length &&
                characValues.map((card, index) => (
                  <Card
                    {...card}
                    key={`safebox-card-${index}`}
                    index={index}
                    taggingInfo={taggingInfo}
                  />
                ))}
            </Carousel>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .safebox-container {
            margin-top: 1.5rem;
            margin-bottom: 1.25rem;
            :global(.safebox--title) {
              margin-top: 0.75rem;
              margin-bottom: 1rem;
            }

            .safebox-card-content {
              .card-container-carousel {
                :global(.carousel) {
                  :global(.carousel-window) {
                    gap: 24px;
                    :global(.carousel-item-wrapper) {
                      width: min-content;
                    }
                    @media screen and (max-width: 620px) {
                      gap: 12px;
                    }
                  }
                }
                .safebox-card-content {
                  display: inline-flex;
                  flex-direction: row;
                  gap: 24px;
                  width: 100%;

                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
              }

              .card-container-grid {
                display: grid;
                gap: 24px;
                grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

                @media screen and (max-width: 768px) {
                  gap: 12px;
                  grid-template-columns: repeat(12, 0fr);
                  overflow-x: auto;
                  overflow-y: hidden;
                }

                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
        `}
      </style>
    </div>
  );
};

export default SafeBoxCard;
