import React from "react";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import dynamic from "next/dynamic";
import getColor from "@gcloud-npm/utils.enums.colors";

const CustomLink = dynamic(() => import("@/bit/components/components.custom-link"));

const Card = (props) => {
  const { index } = props;
  const { taggingInfo } = props;
  const isWebView = process.env.NEXT_PUBLIC_WEB_VIEW === "true";

  return (
    <>
      <div className="interest-card">
        {props["CustomLink-1"] && (
          <CustomLink
            {...props["CustomLink-1"]}
            title={props["CustomLink-1"].title || ""}
            className="overContent"
            taggingInfo={{
              componentName: "interest-card",
              componentContent: props["CustomLink-1"].title,
              componentProduct: props["Text-1"].text || props.alias || "",
              componentVariation: "all",
              eventType: "click",
            }}
            cyData="Card CustomLink-1"
          />
        )}
        <div className="interest-content-container">
          <div>
            <div className="interest-card-text">
              {props["Text-1"] && props["Text-1"].text && (
                <TextInfo
                  {...props["Text-1"]}
                  cyData={`InterestCard-${index} Title`}
                />
              )}
            </div>
            {props["Text-1-Sub"] && props["Text-1-Sub"].text && (
              <div className="interest-card-subtitle">
                <TextInfo
                  {...props["Text-1-Sub"]}
                  cyData={`InterestCard-${index} Title`}
                />
              </div>
            )}
            <div className="interest-card-desc">
              {props["Text-2"] && props["Text-2"].text && (
                <TextInfo
                  {...props["Text-2"]}
                  cyData={`InterestCard-${index} Description`}
                />
              )}
            </div>
          </div>
          <div>
            {props["Price-Text"] && (
              <div className="price-container">
                <TextInfo
                  {...props["Price-Text"]}
                  cyData="InterestCard Price-Text"
                />
              </div>
            )}

            {props["Text-4"] && props["Text-4"].text && (
              <TextInfo
                {...props["Text-4"]}
                cyData={`Maincardteristic-${index} Description`}
              />
            )}

            <div className="interest-buy-button">
              {props["Button-1"] && props["Button-1"].label && (
                <Button
                  {...props["Button-1"]}
                  taggingInfo={taggingInfo}
                  cyData="InterestCard Button-1"
                />
              )}
              {props["Button-3"] && !isWebView && props["Button-3"].label && (
                <Button
                  {...props["Button-3"]}
                  taggingInfo={taggingInfo}
                  cyData="InterestCard Button-3"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .interest-card {
          border-radius: 16px;
          border: 1px solid ${getColor("GREY2")};
          opacity: 1;
          box-sizing: border-box;
          display: flex;
          flex-flow: column nowrap;
          min-width: 310px;
          overflow: hidden;
          position: relative;
          :global(.overContent) {
            z-index: 1;
            &:focus {
              border-radius: 16px;
            }
          }
          &:hover {
            cursor: pointer;
            border: 1px solid ${getColor("GREY3")};
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            min-width: 280px;
          }

          .interest-content-container {
            padding: 0 2rem;
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
              padding: 0 1rem;
            }

            .interest-card-text {
              margin-top: 1.125rem;
              min-height: 36px;
              @media screen and (max-width: 768px) {
                min-height: 30px;
              }
            }

            .interest-card-subtitle {
              margin-bottom: 0.5rem;
            }

            .interest-card-desc {
              flex-wrap: wrap;
              width: 240px;
              margin-bottom: 1rem;

              :global(.text) {
                line-height: 1.5rem;
              }

              @media screen and (max-width: 768px) {
                width: 90%;
              }
            }

            .price-container {
              display: flex;
              gap: 0.75rem;
              padding: 0.4rem 0;
            }

            .interest-buy-button {
              display: flex;
              padding-bottom: 1.5rem;
              position: relative;
              z-index: 2;
              position: relative;

              @media screen and (max-width: 768px) {
                flex-direction: row;
              }
              :global(a) {
                padding: 0 0;
                margin-right: 1rem;
                justify-content: center;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default Card;
