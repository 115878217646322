import React from "react";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import Label from "@/bit/components/components.label";
import Price from "@/bit/components/components.price";
import Image from "@/bit/components/components.c-image";
import CustomLink from "@/bit/components/components.custom-link";
import getColor from "@gcloud-npm/utils.enums.colors";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";

const Card = (props) => {
  const index = props.index;
  const taggingInfoButtonContratar = {
    componentName: "service-card",
    componentVariation: "all",
    componentContent:
      props["Button-1"] && props["Button-1"].label
        ? props["Button-1"].label
        : "",
    componentProduct: props["Text-1"]?.text || props.alias || "",
    eventType: "click",
  };

  const taggingInfoMasInfo = {
    componentName: "service-card",
    componentVariation: "all",
    componentContent:
      props["Button-3"] && props["Button-3"].label
        ? props["Button-3"].label
        : "",
    componentProduct: props["Text-1"]?.text || props.alias || "",
    eventType: "click",
  };

  const isWebView = process.env.NEXT_PUBLIC_WEB_VIEW === "true";
  const dispatch = useDispatch();

  const handleProductModal = () => {
    const ProductModal = dynamic(() => import("@/component/product-modal"));
    dispatch(
      modalActions.addModal({
        id: "product-modal",
        Element2Show: ProductModal,
        modalProps: {
          closeIdByClickingOutside: "closeAll",
          opacityLayoutColor: "blur",
          closeByClickingOutside: true,
          contentInsideContainer: false,
          contentPosition: "right--top",
          inSceneEffect: "fadeInRight",
          outSceneEffect: "fadeOutRight",
          scrollAllowed: false,
          createAnchor: false,
        },
        elementProps: {
          ...props["FlyOut-1"],
        },
      })
    );
  };

  return (
    <>
      <div className="service-card" key={index}>
        {props["CustomLink-1"] && (
          <CustomLink
            {...props["CustomLink-1"]}
            title={props["CustomLink-1"].title || ""}
            className="overContent"
            onClick={
              props["Button-3"] &&
              !isWebView &&
              props["Button-3"].label &&
              !props["Button-3"].href
                ? handleProductModal
                : null
            }
            taggingInfo={{
              componentName: "service-card",
              componentContent: props["CustomLink-1"].title,
              componentProduct: props["Text-1"].text || props.alias || "",
              componentVariation: "all",
              eventType: "click",
            }}
            cyData="Card CustomLink-1"
          ></CustomLink>
        )}

        <div
          className={`service-card-img ${
            !props["Image-1"]?.src ? "without-image" : ""
          }`}
        >
          {props["Image-1"] && props["Image-1"].src && (
            <div className="card-container--image aspect-ratio-3-2">
              <Image
                {...props["Image-1"]}
                fill
                priority={props?.setPriority || false}
                objectFit={
                  props["Image-1"]?.objectFit
                    ? props["Image-1"].objectFit
                    : "cover"
                }
                cyData={`Card Image-1 ${props["Image-1"].alt}`}
              />
            </div>
          )}
          {props["Label-0"] && props["Label-0"].text ? (
            <div className="service-card-recommend">
              <TextInfo
                {...props["Label-0"]}
                cyData={`ServiceCard-${index} Label-0`}
              />
            </div>
          ) : (
            <div className="service-card-empty-recommend" />
          )}
        </div>
        <div className="card-content-container">
          <div>
            <div className="service-card-text">
              {props["Text-1"] &&
                props["Text-1"].text &&
                props["CustomLink-1"] && (
                  <CustomLink
                    {...props["CustomLink-1"]}
                    title={props["CustomLink-1"].title || ""}
                    className="overContent"
                    onClick={
                      props["Button-3"] &&
                      !isWebView &&
                      props["Button-3"].label &&
                      !props["Button-3"].href
                        ? handleProductModal
                        : null
                    }
                    taggingInfo={null}              
                  >
                    <TextInfo
                      {...props["Text-1"]}
                      cyData={`Maincardteristic-${index} Title`}
                    />
                  </CustomLink>
                )}
              {props["Text-1"] &&
                props["Text-1"].text &&
                (!props["CustomLink-1"]) && (
                  <TextInfo
                    {...props["Text-1"]}
                    cyData={`Maincardteristic-${index} Title`}
                  />
                )}
            </div>
            <div className="service-desc">
              {props["Text-2"] && props["Text-2"].text && (
                <TextInfo
                  {...props["Text-2"]}
                  cyData={`Maincardteristic-${index} Description`}
                />
              )}
            </div>
          </div>

          <div>
            {props["Label-1"] && props["Label-1"].label && (
              <Label {...props["Label-1"]} />
            )}

            {props["Text-3"] && props["Text-3"].text && (
              <div className="claim-promo">
                <TextInfo
                  {...props["Text-3"]}
                  cyData={`Maincardteristic-${index} Description`}
                />
              </div>
            )}

            <div className="price-container">
              {props["Price-1"] && <Price {...props["Price-1"]} />}
              {props["Price-2"] && <Price {...props["Price-2"]} />}
              {props["Price-Text"] && (
                <Price
                  {...props["Price-Text"]}
                  cyData="ServiceCard Price-Text"
                />
              )}
            </div>

            {props["Text-4"] && props["Text-4"].text && (
              <TextInfo
                {...props["Text-4"]}
                cyData={`Maincardteristic-${index} Description`}
              />
            )}
            <div className="service-buy-button">
              {props["Button-1"] && props["Button-1"].label && (
                <Button
                  {...props["Button-1"]}
                  taggingInfo={taggingInfoButtonContratar}
                  cyData="ServiceCard Buetton-1"
                />
              )}
              {props["Button-3"] &&
                !isWebView &&
                props["Button-3"].label &&
                props["Button-3"].href && (
                  <Button
                    {...props["Button-3"]}
                    taggingInfo={taggingInfoMasInfo}
                    cyData="ServiceCard Buetton-3"
                  />
                )}
              {props["Button-3"] &&
                !isWebView &&
                props["Button-3"].label &&
                !props["Button-3"].href && (
                  <Button
                    {...props["Button-3"]}
                    onClick={handleProductModal}
                    taggingInfo={taggingInfoMasInfo}
                    cyData="ServiceCard Buetton-3 FlyOut"
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .service-card {
          border-radius: 16px;
          border: 1px solid ${getColor("GREY2")};
          opacity: 1;
          box-sizing: border-box;
          display: flex;
          flex-flow: column nowrap;
          min-width: 348px;
          height: 100%;
          overflow: hidden;
          position: relative;
          :global(.overContent) {
            z-index: 1;
            &:focus {
              border-radius: 16px;
            }
          }
          &:hover {
            cursor: pointer;
            border: 1px solid ${getColor("GREY3")};
          }
          @media screen and (max-width: 1536px) {
            min-width: 316px;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            min-width: 269px;
          }

          .service-card-img {
            position: relative;
            text-align: center;
            color: white;
            &.without-image {
              margin-bottom: 22px;
            }

            :global(picture) {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;

              :global(img) {
                border-radius: 0;
              }
            }
          }

          .service-card-recommend {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #0b2739;
            border-radius: 1rem 0;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 1.6px 1rem;

            :global(.text) {
              line-height: inherit;
            }
          }

          .service-card-empty-recommend {
            background-color: #0b2739;
            display: flex;
            text-align: center;
            justify-content: center;
            border-radius: 16px 16px 0px 0px;
          }

          .card-content-container {
            padding: 0 2rem;
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
              padding: 0 0.9rem;
            }

            .service-card-text {
              margin-bottom: 0.5rem;
              margin-top: 0.75rem;
              min-height: 36px;
              @media screen and (max-width: 768px) {
                min-height: 30px;
              }
            }

            .service-desc {
              flex-wrap: wrap;
              // width: 256px;
              width: auto;
              margin-bottom: 1rem;

              :global(.text) {
                line-height: 1.5rem;
              }

              @media screen and (max-width: 768px) {
                width: 90%;
              }
            }

            .claim-promo {
              padding-top: 0.4rem;
            }

            .price-container {
              display: flex;
              gap: 0.75rem;
              padding: 0.4rem 0;
            }

            .service-promo {
              margin-left: 16px;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 50px;
              height: 22px;
              align-content: center;
              background: ${getColor("RED_DARK")} 0% 0% no-repeat padding-box;
            }

            .service-promo-empty {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 50px;
              height: 22px;
              align-content: center;
              background: white;
            }

            .service-price-text-desc {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              padding-left: 16px;
              padding-right: 16px;
              :global(span) {
                text-decoration: line-through ${getColor("GREY5")};
              }
            }

            .service-buy-button {
              display: flex;
              padding-top: 1rem;
              padding-bottom: 1.5rem;
              justify-content: space-between;
              position: relative;
              z-index: 2;

              @media screen and (max-width: 768px) {
                flex-direction: row;
              }
              :global(a) {
                padding: 0;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default Card;
